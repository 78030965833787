import React from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuPlacement,
  Props as ReactSelectProps,
} from 'react-select';
import SelectClassType from 'react-select/dist/declarations/src/Select';
import { Wrapper } from './styled';
import SelectArrow from '../../../assets/images/selectArrow.svg';
import styled from 'styled-components';
import { TextMedium } from '../../atoms/Typography/Typography';

export interface Option {
  value: string;
  label: string;
}

export interface SelectProps<
  TOption = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
> {
  options: TOption[];
  id?: string;
  menuPlacement?: MenuPlacement;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  onChange?: ReactSelectProps<TOption, IsMulti, Group>['onChange'];
  error?: string;
  value?: TOption;
  defaultValue?: TOption;
}

// type assertion based on https://fettblog.eu/typescript-react-generic-forward-refs/
export const SelectComponent = React.forwardRef(SelectComponentInner) as <
  TOption = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
>(
  props: SelectProps<TOption, IsMulti> & {
    ref?: React.ForwardedRef<SelectClassType<TOption, IsMulti, Group>>;
  },
) => ReturnType<typeof SelectComponentInner>;

function SelectComponentInner<
  TOption = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
>(
  {
    options,
    menuPlacement = 'bottom',
    placeholder,
    id,
    label,
    disabled,
    error,
    defaultValue,
    ...props
  }: SelectProps<TOption, IsMulti>,
  ref: React.ForwardedRef<SelectClassType<TOption, IsMulti, Group>>,
) {
  return (
    <Wrapper error={!!error}>
      {label && <TextMedium $color="delicate">{label}</TextMedium>}
      <Select<TOption, IsMulti, Group>
        classNamePrefix={'Select'}
        components={{
          DropdownIndicator: customDropdownIndicator,
        }}
        defaultValue={defaultValue}
        inputId={id}
        instanceId={id}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
        options={options}
        placeholder={placeholder}
        styles={{
          option: (provided) => ({
            ...provided,
            height: 35,
          }),
        }}
        {...props}
        ref={ref}
      />
      {error && <TextMedium $color="error">{error}</TextMedium>}
    </Wrapper>
  );
}

function customDropdownIndicator<
  TOption,
  IsMulti extends boolean,
  Group extends GroupBase<TOption>,
>({
  ...props
}: JSX.IntrinsicAttributes & DropdownIndicatorProps<TOption, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      {!props.selectProps.menuIsOpen ? (
        <Image src={SelectArrow} />
      ) : (
        <Image src={SelectArrow} style={{ transform: 'rotate(180deg)' }} />
      )}
    </components.DropdownIndicator>
  );
}

const Image = styled.img``;
