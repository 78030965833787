import React from 'react';
import styled, { css } from 'styled-components';
import resourcesBackground from '../../assets/images/resourcesBackground.jpg';
import resourcesMobileBackground from '../../assets/images/resourcesMobileBackground.jpg';
import ScrollLink from '../../components/atoms/ScrollLink';
import { Title, Upheader } from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const IntroSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);
  const tabletLayout = useMaxWidthBreakpoint(1024);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <TextBlock>
          <Upheader $color="delicateAccented">Case Studies</Upheader>
          <SectionTitle>Publications</SectionTitle>
          {!tabletLayout && false && (
            <ScrollLink $color="delicate" to="#resources-content">
              <ScrollIcon />
              Discover
            </ScrollLink>
          )}
        </TextBlock>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    mobileLayout
      ? css`
          background-image: url('${resourcesMobileBackground}');
        `
      : css`
          background-image: url('${resourcesBackground}');
        `}
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 85%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost680pxWide(css`
    min-height: 700px;

    :before {
      width: 100%;
    }
  `)}
`;

const TextBlock = styled.div`
  padding: 150px 0 180px 0;

  ${forScreenAtMost680pxWide(css`
    padding: 174px 0 0 0;
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 553px;
  margin: 7px 0 25px;
`;

export default IntroSection;
