import React from 'react';
import styled from 'styled-components';
import Tabs, { Tab } from './Tabs';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import ResearchIcon from '../../assets/images/NapIcon.inline.svg';
import PublicationIcon from '../../assets/images/capIcon.inline.svg';
import OnlineResourcesIcon from '../../assets/images/OrIcon.inline.svg';

interface ContentSectionProps {
  path: string;
}

const tabsData: Tab[] = [
  {
    title: 'NaturalAntibody Publications',
    icon: <ResearchIcon />,
    path: '/resources/year/',
  },
  {
    title: 'Computational Antibody Papers',
    icon: <PublicationIcon />,
    path: `/resources/research-space/1/`,
  },
  {
    title: 'Online Resources',
    icon: <OnlineResourcesIcon />,
    path: '',
    externalURL: 'https://research.naturalantibody.com',
  },
];

const ContentSection = ({ path }: ContentSectionProps): JSX.Element => {
  return (
    <Wrapper id="resources-content">
      <MainContentColumnWrapper>
        <Tabs tabs={tabsData} path={path} />
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  padding-bottom: 50px;
  /* padding-bottom: 241px; // until newsletter will be restored */
`;

export default ContentSection;
