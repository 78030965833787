import React from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Select } from '../../../components/molecules/Select';
import { forScreenAtMost800pxWide } from '../../../styles/mediaQueries';

interface ResearchSpaceFiltersProps {
  tags: {
    tag: string;
  }[];
  years: {
    year: number;
  }[];
  activeYear?: number;
  activeTag?: string;
}

export const ResearchSpaceFilters = ({
  tags,
  years,
  activeYear,
  activeTag,
}: ResearchSpaceFiltersProps) => {
  const onChangeYear = (year?: string) =>
    navigate(
      year
        ? `/resources/research-space/${year}/1/${
            activeTag?.split(' ').join('_') ?? ''
          }`
        : `/resources/research-space/1/${
            activeTag?.split(' ').join('_') ?? ''
          }`,
    );

  const onChangeTag = (tag?: string) =>
    navigate(
      tag
        ? `/resources/research-space/${
            activeYear ? `${activeYear}/` : ''
          }1/${tag}`
        : `/resources/research-space/${activeYear ? `${activeYear}/` : ''}1/`,
    );

  const yearsOptions = getYearsOptions(years);

  const tagsOptions = getTagsOptions(tags);

  const tagsOptionsValue = tagsOptions.find((tag) => tag.label === activeTag);

  const yearsOptionsValue = yearsOptions.find(
    (year) => year.value === activeYear?.toString(),
  );

  return (
    <ResearchSpaceListWrapper>
      <ContentWrapper>
        <TagsWrapper>
          <SelectText>Filter by tags</SelectText>
          <Select
            options={tagsOptions}
            value={tagsOptionsValue}
            defaultValue={tagsOptions[0]}
            onChange={(newValue) => onChangeTag(newValue?.value)}
          />
        </TagsWrapper>
        <YearsWrapper>
          <SelectText>Filter by published year</SelectText>
          <Select
            options={yearsOptions}
            value={yearsOptionsValue}
            defaultValue={yearsOptions[0]}
            onChange={(newValue) => onChangeYear(newValue?.value)}
          />
        </YearsWrapper>
      </ContentWrapper>
    </ResearchSpaceListWrapper>
  );
};

const getYearsOptions = (
  years: {
    year: number;
  }[],
) => [
  {
    label: 'All',
    value: '',
  },
  ...years.map(({ year }) => ({
    label: year.toString(),
    value: year.toString(),
  })),
];

const getTagsOptions = (
  tags: {
    tag: string;
  }[],
) => [
  {
    label: 'All',
    value: '',
  },
  ...tags.map(({ tag }) => ({
    label: tag,
    value: tag.split(' ').join('_'),
  })),
];
const ResearchSpaceListWrapper = styled.div`
  padding-bottom: 2rem;
  width: 100%;
  background-color: #fff;
  padding-top: 1rem;
`;

const ContentWrapper = styled.div`
  max-width: 1134px;
  margin: 0 auto;
  padding: 0 3rem;
  display: flex;
  gap: 20px;

  ${forScreenAtMost800pxWide(css`
    flex-direction: column;
    width: 100%;
  `)}
`;

const YearsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  .Select__input-container {
    height: 20px;
  }

  ${forScreenAtMost800pxWide(css`
    & > div {
      width: 100%;
    }

    .Select__input-container {
      width: 100%;
    }
  `)}
`;

const TagsWrapper = styled(YearsWrapper)``;

const SelectText = styled.span`
  color: #000;
  font-size: 12px;
  line-height: 28px;
  font-weight: 100;
  letter-spacing: -0.5%;
`;
