import React from 'react';
import styled, { css } from 'styled-components';
import ListMarker from '../../../assets/images/inline.listMarker.svg';
import { forScreenAtMost1024pxWide } from '../../../styles/mediaQueries';
import { Link } from 'gatsby';
import { TextMedium } from '../../../components/atoms/Typography/Typography';

export type ResearchSpace = {
  id: string;
  link: string;
  points: string[];
  title: string;
  tags: string[];
  date: string;
};

interface ResearchSpaceContentProps {
  researchSpaceList: ResearchSpace[] | undefined;
  currentPage?: number;
  pagesCount: number;
  activeYear?: number;
  activeTag?: string;
}

export const ResearchSpaceContent: React.FC<ResearchSpaceContentProps> = ({
  researchSpaceList,
  currentPage = 1,
  pagesCount,
  activeYear,
  activeTag,
}) => {
  if (!researchSpaceList || researchSpaceList.length === 0) {
    <div>No research spaces</div>;
  }

  return (
    <ResearchSpaceListWrapper>
      <ContentWrapper>
        <Header>
          <HeaderItem>Title</HeaderItem>
          <HeaderItem>Key points</HeaderItem>
        </Header>
        <List>
          {researchSpaceList?.map((item) => (
            <ListItem key={item.id}>
              <ListItemLeftSide>
                <DateText $color="delicate">{item.date}</DateText>
                <ListItemTitle>
                  {item.link ? (
                    <Link target="_blank" to={item.link}>
                      {item.title}
                    </Link>
                  ) : (
                    item.title
                  )}
                </ListItemTitle>
                <ListItemTags>
                  {item.tags.map((tag) => (
                    <ListItemTagsItem key={tag}>
                      <ListItemTagsItemText>{tag}</ListItemTagsItemText>
                    </ListItemTagsItem>
                  ))}
                </ListItemTags>
              </ListItemLeftSide>
              <ListItemRightSide>
                <ListKeyPoints>
                  {item.points.map((point, index) => (
                    <ListItemKeyPoints key={`point-${item.id}-${index}`}>
                      <ListItemKeyPointsText bold={index === 0}>
                        {point}
                      </ListItemKeyPointsText>
                    </ListItemKeyPoints>
                  ))}
                </ListKeyPoints>
              </ListItemRightSide>
            </ListItem>
          ))}
        </List>
        {researchSpaceList && researchSpaceList?.length > 0 && (
          <PaginationWrapper>
            <PaginationList>
              {Array.from({ length: pagesCount }).map((_, i) => (
                <Link
                  key={`page-${i}`}
                  to={
                    activeYear
                      ? `/resources/research-space/${activeYear}/${i + 1}/${
                          activeTag ? activeTag?.split(' ').join('_') : ''
                        }`
                      : `/resources/research-space/${i + 1}`
                  }
                >
                  <PaginationStep isActive={i === currentPage - 1}>
                    {i + 1}
                  </PaginationStep>
                </Link>
              ))}
            </PaginationList>
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </ResearchSpaceListWrapper>
  );
};

const PaginationList = styled.ul`
  display: flex;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
`;

const PaginationStep = styled.div<{ isActive: boolean }>`
  width: 3.125rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.texts.delicate};
  background-color: ${({ theme }) => theme.colors.texts.primary};
  margin-right: 0.375rem;
  color: ${({ theme }) => theme.colors.texts.delicate};
  font-size: ${({ theme }) => theme.fontSizes.footer.firstLine};
  display: flex;
  justify-content: center;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.backgrounds.secondaryPair};
      color: ${({ theme }) => theme.colors.backgrounds.focus};
    `}
`;

export const RouterPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
  align-items: flex-end;
`;

const ResearchSpaceListWrapper = styled.div`
  padding-bottom: 20rem;
  width: 100%;
  background-color: #fff;
  padding-top: 1rem;

  ${forScreenAtMost1024pxWide(css`
    padding-bottom: 25rem;
  `)};
`;

const ContentWrapper = styled.div`
  max-width: 1134px;
  margin: 0 auto;
`;

const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 420px 1fr;
  border-bottom: 1px solid #d0d7d8;

  ${forScreenAtMost1024pxWide(css`
    display: none;
  `)};
`;

const HeaderItem = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.texts.delicateAccented};
  font-size: 11px;
  padding: 1rem 3rem;
  letter-spacing: 1px;
  line-height: 13.2px;
`;

const List = styled.ul`
  margin-top: 3rem;
  width: 100%;
  padding-left: 0;

  ${forScreenAtMost1024pxWide(css`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  `)};
`;

const ListItem = styled.li`
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  border-bottom: 1px solid #d0d7d8;

  ${forScreenAtMost1024pxWide(css`
    display: flex;
    flex-direction: column;
    padding: 0;
  `)};
`;

const ListItemLeftSide = styled.div`
  margin-right: 4rem;

  ${forScreenAtMost1024pxWide(css`
    padding: 0 10px;
    margin: 0;
  `)}
`;

const ListItemTitle = styled.h3`
  color: #134f58;
  /* color: ${({ theme }) => theme.colors.texts.delicateAccented}; */
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -3%;
  margin-block-start: 0;

  a {
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.texts.delicateAccented};
      cursor: pointer;
    }
  }
`;

const ListItemTags = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  padding: 1rem 0 0 0;
  flex-wrap: wrap;
  list-style: none;
`;

const ListItemTagsItem = styled.li`
  background-color: #93b5b1;
  border-radius: 15px;
  width: fit-content;
  padding: 6px 10px;
  margin: 3px;
`;

const ListItemTagsItemText = styled.span`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
`;

const ListItemRightSide = styled.div`
  ${forScreenAtMost1024pxWide(css`
    padding-left: 0;
  `)};
`;

const ListKeyPoints = styled.ul`
  list-style-type: circle;

  ${forScreenAtMost1024pxWide(css`
    margin-top: 1rem;
    padding-left: 15px;
    padding-bottom: 2rem;
  `)};
`;

const ListItemKeyPoints = styled.li`
  list-style: url('${ListMarker}');
  margin-bottom: 1rem;
`;

const ListItemKeyPointsText = styled.span<{ bold: boolean }>`
  color: #000;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
  line-height: 28px;
  letter-spacing: -0.5%;
`;

const PaginationWrapper = styled.div`
  padding-left: 2rem;

  ${forScreenAtMost1024pxWide(css`
    display: flex;
    justify-content: center;
    padding-left: 0;
  `)};
`;

const DateText = styled(TextMedium)``;
