import styled from 'styled-components';
import { TextMedium } from '../../atoms/Typography/Typography';

export const Wrapper = styled.div<{ error?: boolean }>`
  width: max-content;

  ${TextMedium} {
    line-height: 1.7;
    width: 100%;
  }
  color: black !important;
  .Select__control {
    border-color: ${({ theme, error }) =>
      error ? theme.colors.texts.error : theme.colors.texts.delicate};
    border-radius: 0;
    font-size: ${({ theme }) => theme.fontSizes.forms.input};
    box-shadow: none;
    ${({ theme }) => theme.fontSizes.forms.input};
    background-color: ${({ theme, error }) =>
      error ? theme.colors.texts.error : theme.colors.texts.primary};

    &--is-focused,
    &--menu-is-opened {
      border-color: ${({ theme, error }) =>
        error
          ? theme.colors.texts.error
          : theme.colors.texts.activeMenuLinkSecondary} !important;
    }

    &--is-disabled {
      border-color: ${({ theme }) => theme.colors.texts.delicate};
      background-color: ${({ theme }) => theme.colors.texts.primary};
      opacity: 0.3;
    }

    &:hover {
      border-color: ${({ theme, error }) => error && theme.colors.texts.error};
    }

    .Select__value-container {
      padding: 0.75rem 1.125rem;

      .Select__input-container {
        padding: 0;
        margin: 0;
        min-width: 7.5rem;
      }
    }

    .Select__placeholder {
      color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
      line-height: 1.5;
    }

    .Select__indicator-separator {
      display: none;
    }

    .Select__menu {
      border-radius: 0;
      margin-top: 0;
      font-size: ${({ theme }) => theme.fontSizes.forms.input};

      .Select__option {
        padding-left: 1.125rem;

        &--is-focused {
          background-color: ${({ theme }) =>
            theme.colors.texts.tertiaryStronger};
        }

        &--is-selected {
          background-color: ${({ theme }) => theme.colors.texts.tertiary};
        }
      }
    }
  }
`;
