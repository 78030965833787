import React from 'react';
import styled, { css } from 'styled-components';
import {
  GradientHeader,
  Header3,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost800pxWide,
  forScreenAtMost1024pxWide,
  forScreenAtMost1200pxWide,
} from '../../styles/mediaQueries';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { ExternalLink } from '../../components/atoms/Link';
import orbits from '../../assets/images/orbits.svg';
import { Link } from 'gatsby';

export type Tab = {
  title: string;
  icon?: React.ReactElement;
  path: string;
  externalURL?: string;
};

interface TabsProps {
  tabs: Tab[];
  path: string;
}

export const Tabs = ({ tabs, path }: TabsProps): JSX.Element => {
  const tabletLayout = useMaxWidthBreakpoint(1024);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <TabsWrapper>
      {tabletLayout && <TabsUpheader>Categories</TabsUpheader>}
      <TabsMenuWrapper>
        {tabs.map((tab) => {
          return (
            <SingleTabMenu
              active={
                (!tab.externalURL && path.indexOf(tab.path) > -1) ?? false
              }
              key={tab.title}
            >
              {tab.icon && tab.icon}
              <Header3 $color="delicate">
                {!tab.externalURL && (
                  <TabLink to={tab.path}>{tab.title}</TabLink>
                )}
                {tab.externalURL && (
                  <ExternalLink href={tab.externalURL}>
                    {tab.title}
                  </ExternalLink>
                )}
              </Header3>
            </SingleTabMenu>
          );
        })}
      </TabsMenuWrapper>
    </TabsWrapper>
  );
};

const TabLink = styled(Link)`
  text-decoration: none;
`;

const TabsWrapper = styled.div`
  margin-top: -94px;
  position: relative;
  z-index: ${({ theme }) => theme.layers.base};

  ${forScreenAtMost1024pxWide(css`
    margin-top: -129px;
  `)};
`;

const TabsUpheader = styled(Upheader).attrs({
  $color: 'delicateAccented',
  $textAlign: 'center',
})`
  margin: 0 0 22px;
`;

const TabsMenuWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  ${forScreenAtMost1200pxWide(css`
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 -30px 0 -20px;
  `)}
`;

const SingleTabMenu = styled.li<{ active: boolean }>`
  height: 84px;
  padding: 30px 26px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  transition: all 0.2s;

  svg {
    margin-right: 15px;
  }

  ${Header3} {
    white-space: nowrap;
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.backgrounds.secondary};

      ${Header3} {
        color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
      }
    `};

  ${forScreenAtMost800pxWide(css`
    padding: 30px 16px;
  `)};
`;

export const TabsContentWrapper = styled.div`
  padding-top: 63px;

  ${forScreenAtMost1024pxWide(css`
    padding-top: 65px;
  `)};
`;

export const SingleTabContent = styled.div<{ active: boolean }>`
  display: none;

  ${({ active }) =>
    active &&
    css`
      display: block;
    `};
`;

export default Tabs;

export const TabContentTitle = styled(GradientHeader).attrs({
  $gradient: 'primary',
})`
  margin-bottom: 38px;
  position: relative;

  :before {
    content: '';
    z-index: ${({ theme }) => theme.layers.base - 1};

    position: absolute;
    top: 20px;
    left: 0;

    transform: translate(-50%, -50%);

    width: 409px;
    height: 409px;

    background: url('${orbits}');
  }
`;
