import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../../components/layout/Layout';
import { FooterBoxContent } from '../../../components/layout/Footer';
import IntroSection from '../../../pages-components/resources/IntroSection';
import {
  ResearchSpace,
  ResearchSpaceContent,
} from '../../../pages-components/resources/ResearchSpace/ResearchSpaceContent';
import ContentSection from '../../../pages-components/resources/ContentSection';
import { ResearchSpaceFilters } from '../../../pages-components/resources/ResearchSpaceFilters/ResearchSpaceFilters';

export interface ResearchSpaceProps {
  data: {
    allSanityResearchSpace: {
      nodes: ResearchSpace[];
      totalCount: number;
    };
    allSanityResearchSpaceTags: {
      nodes: {
        tag: string;
      }[];
    };
    allSanityResearchSpaceYears: {
      nodes: {
        year: number;
      }[];
    };
  };
  pageContext: {
    currentPage?: number;
    year?: number;
    tag?: string;
    numPages?: number;
  };
  location: {
    pathname: string;
  };
}

export const queryGraphql = graphql`
  query allSanityResearchSpaceTagsQueryAndAllSanityResearchSpaceQuery(
    $skip: Int = 0
    $limit: Int = 10
    $tag: String
    $gte: Date
    $lte: Date
  ) {
    allSanityResearchSpace(
      skip: $skip
      limit: $limit
      filter: { tags: { eq: $tag }, date: { gte: $gte, lte: $lte } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      nodes {
        id
        link
        title
        points
        tags
        date
      }
    }

    allSanityResearchSpaceTags {
      nodes {
        tag
      }
    }

    allSanityResearchSpaceYears {
      nodes {
        year
      }
    }
  }
`;

const ResourcesPage = (props: ResearchSpaceProps): JSX.Element => {
  return (
    <Layout
      description="Research is the foundation of our work in developing antibody analytics solutions. Discover our work with leading academic and industrial partners."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Resources | Antibody Engineering Insights"
    >
      <IntroSection />
      <ContentSection path={props.location.pathname} />
      <ResearchSpaceFilters
        tags={props.data.allSanityResearchSpaceTags.nodes}
        years={props.data.allSanityResearchSpaceYears.nodes}
        activeYear={props.pageContext.year}
        activeTag={props.pageContext.tag}
      />
      <ResearchSpaceContent
        currentPage={props.pageContext.currentPage}
        researchSpaceList={props.data.allSanityResearchSpace.nodes}
        pagesCount={props.pageContext.numPages ?? 1}
        activeYear={props.pageContext.year}
        activeTag={props.pageContext.tag}
      />
    </Layout>
  );
};

export default ResourcesPage;
