import React from 'react';
import styled from 'styled-components';
import { animation, ScrollAnimation } from '../../styles/GlobalStyles';
import { LinkProps, UpperCaseLinkSmall } from './Link';

interface ScrollLinkProps extends LinkProps {
  $marginTop?: string;
  children: React.ReactNode;
  to: string;
}

const ScrollLink = ({
  $marginTop,
  to,
  children,
  ...rest
}: ScrollLinkProps): JSX.Element => (
  <StyledLink $marginTop={$marginTop} to={to} {...rest}>
    {children}
  </StyledLink>
);

const StyledLink = styled(UpperCaseLinkSmall).attrs({
  $color: 'delicate',
  $hover: 'scrollLinkHover',
  $focus: 'scrollLinkHover',
  $focusBg: 'focus',
})<{ $marginTop?: string }>`
  display: inline-flex;
  align-items: center;
  margin-top: ${({ $marginTop }) => $marginTop || '0px'};

  svg {
    margin-right: 10px;
    ${animation(0, 2, ScrollAnimation)};
    animation-iteration-count: infinite;
  }
`;

export default ScrollLink;
